/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="inline-block wordBank">
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Input Validation</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Code Review</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">WAF</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Url Filtering</h5>
              <h5 class="dragBlock my-4 font-semibold cursor-pointer">Record Level Access Control</h5>
            </vue-draggable-container>
          </vx-card>
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Attack Type</h3>
            <v-select placeholder="Select Attack Type" class="w-full" :options="attack_type_options" v-model="selected_attack_type" />
            <small v-if="correct_attack_type && correct_attack_type === false" class="ml-1 text-danger font-semibold"
              >This answer is incorrect</small
            >
            <small v-if="correct_attack_type && correct_attack_type === true" class="ml-1 text-success font-semibold"
              >This answer is correct</small
            >
            <small v-if="!selected_attack_type" class="ml-1 text-danger font-semibold"
              >Please select an attack type before proceeding</small
            >
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="">
              <div class="simulation-10">
                <div class="vx-row mb-16 mt-16">
                  <div class="vx-col w-1/5 center-2">
                    <div
                      class="tenth-firewall-block-2 cursor-pointer"
                      style="background-color: #00aaff"
                      @click="show_attacker_tablet = !show_attacker_tablet"
                      :class="show_attacker_tablet ? 'bg-danger' : ''"
                    >
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/tablet.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Attacker Tablet</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/server.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Anonymiser</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/internet.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Internet</h1>
                    </div>
                    <div class="line-sim-792"></div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/wall.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Firewall</h1>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/network-switch.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Switch A</h1>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-16 mt-16">
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/wifi-router.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Router</h1>
                    </div>
                    <div class="line-internet-up"></div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="line-internet-up"></div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="line-internet-up"></div>
                  </div>
                </div>
                <div class="vx-row mb-16 mt-16">
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2" style="min-width: 10vw; padding-top: 0px">
                      <h1 class="tenth-internet-text mb-1 text-left">Application Source Code within Repository</h1>
                      <vue-draggable-container
                        id="input0"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                      <vue-draggable-container
                        id="input1"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2">
                      <div class="tenth-image-block">
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/network.png"
                          width="60"
                        />
                      </div>
                      <h1 class="tenth-internet-text">Switch B</h1>
                    </div>
                    <div class="line-internet-up"></div>
                    <div class="line-sim-792-short"></div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2" style="min-width: 10vw; padding-top: 5px">
                      <div class="flex justify-between align-middle items-center">
                        <h1 class="tenth-internet-text mb-1 text-left">Web Server</h1>
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/server-2.png"
                          width="30"
                        />
                      </div>
                      <vue-draggable-container
                        id="input4"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                      <vue-draggable-container
                        id="input5"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2" style="min-width: 10vw; padding-top: 5px">
                      <div class="flex justify-between align-middle items-center">
                        <h1 class="tenth-internet-text mb-1 text-left">Database</h1>
                        <img
                          draggable="false"
                          alt=""
                          class="tenth-image"
                          src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/database-storage.png"
                          width="30"
                        />
                      </div>
                      <vue-draggable-container
                        id="input6"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                      <vue-draggable-container
                        id="input7"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-16 mt-16">
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2">
                    <div class="tenth-firewall-block-2" style="min-width: 10vw; padding-top: 0px">
                      <h1 class="tenth-internet-text mb-1 text-left">CRM Server</h1>
                      <vue-draggable-container
                        id="input2"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                      <vue-draggable-container
                        id="input3"
                        class="answerStandard dropzone dropzoneStandard answer-bg my-2"
                      ></vue-draggable-container>
                    </div>
                  </div>
                  <div class="vx-col w-1/5 center-2"></div>
                  <div class="vx-col w-1/5 center-2"></div>
                </div>
              </div>
            </div>
          </vx-card>
          <slide-y-up-transition>
            <!--- ATTACKERS TABLET --->
            <div v-if="show_attacker_tablet" style="position: absolute; top: 34%; left: 21.5vw; z-index: 999999">
              <div class="marvel-device iphone-x landscape">
                <div class="notch">
                  <div class="camera"></div>
                  <div class="speaker"></div>
                </div>
                <div class="top-bar"></div>
                <div class="sleep"></div>
                <div class="bottom-bar"></div>
                <div class="volume"></div>
                <div class="overflow">
                  <div class="shadow shadow--tr"></div>
                  <div class="shadow shadow--tl"></div>
                  <div class="shadow shadow--br"></div>
                  <div class="shadow shadow--bl"></div>
                </div>
                <div class="inner-shadow"></div>
                <div class="screen flex items-center justify-center align-middle">
                  <div class="p-8 pt-2 pb-4 rounded" v-if="attacker_tablet_login === false" style="backdrop-filter: blur(30px)">
                    <h1 class="font-bold my-3">Attacker Tablet</h1>
                    <h3 class="my-2 border border-solid border-dark p-2 rounded">FBI-395025</h3>
                    <h3 class="my-2 border border-solid border-dark p-2 rounded">••••••••••••••</h3>
                    <vs-button color="dark" type="border" class="my-2 w-full" @click="login_attacker_tablet">Login</vs-button>
                  </div>
                  <div class="p-8 pt-2 pb-4 rounded" v-else style="backdrop-filter: blur(30px)">
                    <h1 class="font-bold my-3">Logged Into Online Games</h1>
                    <h4 class="my-2 font-semibold">
                      User,cookie-id,login-time<br />Pete,123511235adf89866eaf,2012-.0-21 15:34:34<br />Matt,efda836a8321ff23213,2012-03-21
                      15:37:34<br />Sara,123e13afd358fa7499d, 2012-03-21 15-39:34
                    </h4>
                  </div>
                  <div class="flex text-white ml-2 cursor-pointer" @click="show_attacker_tablet = false">
                    <feather-icon icon="XCircleIcon" svgClasses="w-8 h-8" />
                  </div>
                </div>
              </div>
            </div>
          </slide-y-up-transition>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import vSelect from 'vue-select';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      selected_attack_type: null,
      correct_attack_type: null,

      show_attacker_tablet: false,
      attacker_tablet_login: false,

      attack_type_options: [
        { id: 1, label: 'SQL Injection' },
        { id: 2, label: 'Cross Site Scripting' },
        { id: 3, label: 'XML Injection' },
        { id: 4, label: 'Session Hijacking' },
        { id: 5, label: 'Buffer Overflow' },
      ],
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    login_attacker_tablet() {
      this.attacker_tablet_login = true;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      this.show_attacker_tablet = false;

      if (this.attacker_tablet_login === false) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'Not Logged Into Tablet',
          text: 'You must login into the tablet before proceeding',
        });
        return this.$emit('simulation_getting_there', 0, false);
      }

      if (this.selected_attack_type === null) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: 'No Attack Type Selected',
          text: 'You must select an attack type before proceeding',
        });
        return this.$emit('simulation_getting_there', 0, false);
      }

      //INPUT 1 AND 2

      const input0 = document.getElementById('input0');
      const input1 = document.getElementById('input1');

      if (input0.textContent === 'Code Review' || input1.textContent === 'Code Review') {
        input0.style.borderColor = '#28C76F';
        input0.style.backgroundColor = '#28C76F';
        input1.style.borderColor = '#28C76F';
        input1.style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        input0.style.borderColor = '#fd4445';
        input0.style.backgroundColor = '#fd4445';
        input1.style.borderColor = '#fd4445';
        input1.style.backgroundColor = '#fd4445';
      }

      //INPUT 2 & 3

      const input2 = document.getElementById('input2');
      const input3 = document.getElementById('input3');

      if (input2.textContent === 'Record Level Access Control' || input3.textContent === 'Record Level Access Control') {
        input2.style.borderColor = '#28C76F';
        input2.style.backgroundColor = '#28C76F';
        input3.style.borderColor = '#28C76F';
        input3.style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        input2.style.borderColor = '#fd4445';
        input2.style.backgroundColor = '#fd4445';
        input3.style.borderColor = '#fd4445';
        input3.style.backgroundColor = '#fd4445';
      }

      //INPUT 4 & 5
      const input4 = document.getElementById('input4');
      const input5 = document.getElementById('input5');

      if (input4.textContent === 'Url Filtering' || input4.textContent === 'WAF') {
        input4.style.borderColor = '#28C76F';
        input4.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input5.textContent !== 'Url Filtering' || input5.textContent !== 'WAF') {
        input4.style.borderColor = '#fd4445';
        input4.style.backgroundColor = '#fd4445';
      }

      if (input5.textContent === 'Url Filtering' || input5.textContent === 'WAF') {
        input5.style.borderColor = '#28C76F';
        input5.style.backgroundColor = '#28C76F';
        totalScore++;
      } else if (input5.textContent !== 'Url Filtering' || input5.textContent !== 'WAF') {
        input5.style.borderColor = '#fd4445';
        input5.style.backgroundColor = '#fd4445';
      }

      //INPUT 6 & 7
      const input6 = document.getElementById('input6');
      const input7 = document.getElementById('input7');

      if (input6.textContent === 'Input Validation' || input7.textContent === 'Input Validation') {
        input6.style.borderColor = '#28C76F';
        input6.style.backgroundColor = '#28C76F';
        input7.style.borderColor = '#28C76F';
        input7.style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        input6.style.borderColor = '#fd4445';
        input6.style.backgroundColor = '#fd4445';
        input7.style.borderColor = '#fd4445';
        input7.style.backgroundColor = '#fd4445';
      }

      if (this.selected_attack_type && this.selected_attack_type.id === 2) {
        totalScore++;
        this.correct_attack_type = true;
      } else {
        this.correct_attack_type = false;
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }
      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    vSelect,
  },
  created() {},
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx202100746110369792.scss';
@import '@/views/platform/modules/simulations/legacy/css/Generic/Devices.scss';

.iphone-x .screen {
  background-image: url('https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/202100746110369792/fbi-background.jpg');
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
